import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Vue } from "vue-property-decorator";

export default function useUsersList() {
  // Use toast

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "fullName", label: "name", sortable: true },
    { key: "title", sortable: true },
    // {
    //   key: 'university',
    //   label: 'Education',
    //   formatter: title,
    //   sortable: true,
    // },
    { key: "mobileNumber", sortable: true },
    { key: "email", sortable: true },
    { key: "actions", sortable: false },
  ];
  // { key: 'status', sortable: false },

  const perPage = ref(Number(store.state.appConfig.perPage));
  const totalUsers = ref(0);
  const currentPage = ref(JSON.parse(localStorage.getItem("currentPage")) || 1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref([]);
  const searchName = ref("");
  const searchTitle = ref("");
  const sortBy = ref(store.state.appConfig.sortBy || "id");
  const isSortDirDesc = ref(store.state.appConfig.isSortDirDesc);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const loading = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchName,
      searchTitle,
      searchQuery,
      roleFilter,
      planFilter,
      statusFilter,
      isSortDirDesc,
    ],
    () => {
      if ((searchName.value.length === 0) | (searchName.value.length >= 3)) {
        console.log("searchName", searchName.value);
        refetchData();
      }

      else if ((searchTitle.value.length === 0) | (searchTitle.value.length >= 3)) {
        console.log("searchTitle", searchTitle.value);
        refetchData();
      }
      store.commit("appConfig/UPDATE_PERPAGE", perPage.value);
      currentPage.value = store.state.appConfig.currentPage;
    }
  );

  watch([searchName, searchTitle, searchQuery], () => {
    currentPage.value = 1;
    sortBy.value = "id";
    isSortDirDesc.value = true;
    store.commit("appConfig/UPDATE_CURRENTPAGE", 1);
  });

  watch([sortBy, isSortDirDesc], () => {
    store.commit("appConfig/UPDATE_SORTBY", sortBy.value);
    store.commit("appConfig/UPDATE_DIR", isSortDirDesc.value);
  });

  const fetchUsers = (ctx, callback) => {
    loading.value = true;
    store
      .dispatch("app-user/fetchUsers", {
        pageSize: perPage.value,
        currentPage: currentPage.value,
        sortExpression: sortBy.value,
        sortDirection: isSortDirDesc.value ? "desc" : "asc",
        includeDeletedRecords: false,
        searchName: searchName.value,
        searchTitle: searchTitle.value,
        searchFilters: searchQuery.value,
        includeInactiveCandidates: true,
        includeBlacklistedCandidates: true,
        isactiveList: false,
      })
      .then((response) => {
        const { items, totalCount } = response.data.data;

        callback(items);
        loading.value = false;
        totalUsers.value = totalCount;
      })
      .catch(() => {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: "error",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    isactiveList.value = false;
  };

  const fetchCandidates = (ctx, callback) => {
    loading.value = true;
    store
      .dispatch("app-user/fetchUsers", {
        pageSize: perPage.value,
        currentPage: currentPage.value,
        sortExpression: sortBy.value,
        sortDirection: isSortDirDesc.value ? "desc" : "asc",
        includeDeletedRecords: false,
        searchName: searchName.value,
        searchTitle: searchTitle.value,
        searchFilters: searchQuery.value,
        includeInactiveCandidates: true,
        includeBlacklistedCandidates: true,
        isactiveList: true,
      })
      .then((response) => {
        const { items, totalCount } = response.data.data;

        callback(items);
        loading.value = false;
        totalUsers.value = totalCount;
      })
      .catch(() => {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: "error",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    isactiveList.value = false;
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "maintainer") return "success";
    if (role === "editor") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === "subscriber") return "UserIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "editor") return "Edit2Icon";
    if (role === "admin") return "ServerIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "Inactive") return "warning";
    if (status === "Active") return "success";
    if (status === "Blacklisted") return "secondary";
    return "primary";
  };

  return {
    fetchUsers,
    fetchCandidates,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    searchName,
    searchTitle,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    loading,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    roleFilter,
    planFilter,
    statusFilter,
  };
}
